namespace $ {
	export class $hyoo_about extends $mol_view {
		
		/**
		 * ```tree
		 * sub / <= Page
		 * ```
		 */
		sub() {
			return [
				this.Page()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Sources $mol_link_source
		 * ```
		 */
		@ $mol_mem
		Sources() {
			const obj = new this.$.$mol_link_source()
			
			return obj
		}
		
		/**
		 * ```tree
		 * text1 \
		 * 	\Мы - группа профессионалов, специализирующаяся на амбициозных WEB и IOT проектах.
		 * 	\
		 * 	\Мы разработали [экосистему микромодулей MAM](https://github.com/hyoo-ru/mam) позволяющую легко обмениваться кодом и держать руку на пульсе последних обновлений.
		 * 	\
		 * 	\В её рамках разработали [фреймворк $mol](https://mol.hyoo.ru/) и экосистему компонент для него, позволяющую за считанные дни получать компактные и шустрые [PWA](https://ru.wikipedia.org/wiki/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B5%D1%81%D1%81%D0%B8%D0%B2%D0%BD%D0%BE%D0%B5_%D0%B2%D0%B5%D0%B1-%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5) приложения.
		 * 	\
		 * 	\На его основе мы делаем экосистему opensource продуктов [hyoo.ru](https://apps.hyoo.ru/), синергетически интегрируемых друг с другом, и реализующих инновационные подходы к UX, архитектуре и алгоритмам.
		 * 	\
		 * 	\Всё это объединено в экосистему видео и телеграм каналов и чатов. А так же написано [множество статей](https://habhub.hyoo.ru/) и [докладов](https://slides.hyoo.ru/) на различных конференциях, где мы делимся результатами своих исследований.
		 * ```
		 */
		text1() {
			return "Мы - группа профессионалов, специализирующаяся на амбициозных WEB и IOT проектах.\n\nМы разработали [экосистему микромодулей MAM](https://github.com/hyoo-ru/mam) позволяющую легко обмениваться кодом и держать руку на пульсе последних обновлений.\n\nВ её рамках разработали [фреймворк $mol](https://mol.hyoo.ru/) и экосистему компонент для него, позволяющую за считанные дни получать компактные и шустрые [PWA](https://ru.wikipedia.org/wiki/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B5%D1%81%D1%81%D0%B8%D0%B2%D0%BD%D0%BE%D0%B5_%D0%B2%D0%B5%D0%B1-%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5) приложения.\n\nНа его основе мы делаем экосистему opensource продуктов [hyoo.ru](https://apps.hyoo.ru/), синергетически интегрируемых друг с другом, и реализующих инновационные подходы к UX, архитектуре и алгоритмам.\n\nВсё это объединено в экосистему видео и телеграм каналов и чатов. А так же написано [множество статей](https://habhub.hyoo.ru/) и [докладов](https://slides.hyoo.ru/) на различных конференциях, где мы делимся результатами своих исследований."
		}
		
		/**
		 * ```tree
		 * Text1 $mol_text text <= text1
		 * ```
		 */
		@ $mol_mem
		Text1() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.text1()
			
			return obj
		}
		
		/**
		 * ```tree
		 * text2 \
		 * 	\1. Вас не устраивает текущая скорость и качество web-разработки. Вы хотите изменить, завязанную на хайп и иностранные технологии (google, facebook) культуру программирования. Вы хотите быстро создавать гибкие web или electron приложения под **все платформы** наименьшими силами, переиспользуя решения на высоком уровне в масштабе всей экосистемы.
		 * 	\2. *Что мы можем дать:* небольшое, но активное сообщество, высокий уровень инженерии, масштабирование, гибкость. Технологию **синхронизации** данных в реальном времени между устройствами и пользователями: **offline first**
		 * 	\3. *Подводные камни:* Рынок разработчиков придется создавать с нуля, вкладываясь в обучение. Мы готовы обучать, но наши ресурсы не безграничны.
		 * ```
		 */
		text2() {
			return "1. Вас не устраивает текущая скорость и качество web-разработки. Вы хотите изменить, завязанную на хайп и иностранные технологии (google, facebook) культуру программирования. Вы хотите быстро создавать гибкие web или electron приложения под **все платформы** наименьшими силами, переиспользуя решения на высоком уровне в масштабе всей экосистемы.\n2. *Что мы можем дать:* небольшое, но активное сообщество, высокий уровень инженерии, масштабирование, гибкость. Технологию **синхронизации** данных в реальном времени между устройствами и пользователями: **offline first**\n3. *Подводные камни:* Рынок разработчиков придется создавать с нуля, вкладываясь в обучение. Мы готовы обучать, но наши ресурсы не безграничны."
		}
		
		/**
		 * ```tree
		 * CTO_text $mol_text text <= text2
		 * ```
		 */
		@ $mol_mem
		CTO_text() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.text2()
			
			return obj
		}
		
		/**
		 * ```tree
		 * CTO $mol_expander
		 * 	title \Техническому директору
		 * 	Content <= CTO_text
		 * ```
		 */
		@ $mol_mem
		CTO() {
			const obj = new this.$.$mol_expander()
			
			obj.title = () => "Техническому директору"
			obj.Content = () => this.CTO_text()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Student_text $mol_text text \
		 * 	\1. У вас мало опыта в разработке. Нет предпочтений по технологиям. Нравится изучать что-то новое. Хотите делать крутые штуки, но не тратить на это много времени. Имеете много свободного времени. Не любите читать документацию. Предпочитаете учиться на примерах.
		 * 	\2. *Что мы можем дать:* Вау-фичи, которых нигде нет. Возможность малыми усилиями сделать много. Чат, где ответят на все ваши вопросы.
		 * 	\3. *Подводные камни:* Малоизвестность. Отсутствие спроса на рынке. Непохожесть на популярные технологии. Сложность поиска готовых примеров под ваши потребности, хоть мы и постоянно расширяем базу примеров.
		 * ```
		 */
		@ $mol_mem
		Student_text() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => "1. У вас мало опыта в разработке. Нет предпочтений по технологиям. Нравится изучать что-то новое. Хотите делать крутые штуки, но не тратить на это много времени. Имеете много свободного времени. Не любите читать документацию. Предпочитаете учиться на примерах.\n2. *Что мы можем дать:* Вау-фичи, которых нигде нет. Возможность малыми усилиями сделать много. Чат, где ответят на все ваши вопросы.\n3. *Подводные камни:* Малоизвестность. Отсутствие спроса на рынке. Непохожесть на популярные технологии. Сложность поиска готовых примеров под ваши потребности, хоть мы и постоянно расширяем базу примеров."
			
			return obj
		}
		
		/**
		 * ```tree
		 * Student $mol_expander
		 * 	title \Новичку
		 * 	Content <= Student_text
		 * ```
		 */
		@ $mol_mem
		Student() {
			const obj = new this.$.$mol_expander()
			
			obj.title = () => "Новичку"
			obj.Content = () => this.Student_text()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Senior_text $mol_text text \
		 * 	\1. У вас много опыта, есть осознание, что даже брендовые технологии перенасыщены костылями, сильно тормозящими разработку. Хочется более высокоуровневой, но гибкой разработки.
		 * 	\2. *Что мы можем дать:* Возможно делать больше, концентрируясь на продукте, а не на низкоуровневом выпиливании напильником.
		 * 	\3. *Подводные камни:* Плохая совместимость с существующей экосистемой, непривычность для программистов и дизайнеров, нужно формировать другую культуру разработки (переиспользование, универсальность, скорость).
		 * ```
		 */
		@ $mol_mem
		Senior_text() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => "1. У вас много опыта, есть осознание, что даже брендовые технологии перенасыщены костылями, сильно тормозящими разработку. Хочется более высокоуровневой, но гибкой разработки.\n2. *Что мы можем дать:* Возможно делать больше, концентрируясь на продукте, а не на низкоуровневом выпиливании напильником.\n3. *Подводные камни:* Плохая совместимость с существующей экосистемой, непривычность для программистов и дизайнеров, нужно формировать другую культуру разработки (переиспользование, универсальность, скорость)."
			
			return obj
		}
		
		/**
		 * ```tree
		 * Senior $mol_expander
		 * 	title \Сеньору
		 * 	Content <= Senior_text
		 * ```
		 */
		@ $mol_mem
		Senior() {
			const obj = new this.$.$mol_expander()
			
			obj.title = () => "Сеньору"
			obj.Content = () => this.Senior_text()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Content $mol_list rows /
		 * 	<= Text1
		 * 	<= CTO
		 * 	<= Student
		 * 	<= Senior
		 * ```
		 */
		@ $mol_mem
		Content() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Text1(),
				this.CTO(),
				this.Student(),
				this.Senior()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Page $mol_page
		 * 	title \ТехноГильдия
		 * 	tools / <= Sources
		 * 	body / <= Content
		 * ```
		 */
		@ $mol_mem
		Page() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "ТехноГильдия"
			obj.tools = () => [
				this.Sources()
			] as readonly any[]
			obj.body = () => [
				this.Content()
			] as readonly any[]
			
			return obj
		}
	}
	
}

