namespace $ {
	export class $mol_icon extends $mol_svg_root {
		
		/**
		 * ```tree
		 * view_box \0 0 24 24
		 * ```
		 */
		view_box() {
			return "0 0 24 24"
		}
		
		/**
		 * ```tree
		 * minimal_width 16
		 * ```
		 */
		minimal_width() {
			return 16
		}
		
		/**
		 * ```tree
		 * minimal_height 16
		 * ```
		 */
		minimal_height() {
			return 16
		}
		
		/**
		 * ```tree
		 * sub / <= Path
		 * ```
		 */
		sub() {
			return [
				this.Path()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * path \
		 * ```
		 */
		path() {
			return ""
		}
		
		/**
		 * ```tree
		 * Path $mol_svg_path geometry <= path
		 * ```
		 */
		@ $mol_mem
		Path() {
			const obj = new this.$.$mol_svg_path()
			
			obj.geometry = () => this.path()
			
			return obj
		}
	}
	
}

