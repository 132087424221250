namespace $ {
	export class $mol_icon_chevron extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z
		 * ```
		 */
		path() {
			return "M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
		}
	}
	
}

